import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const HomeDashboardTag = Loadable(lazy(() => import('views/dashboard/Default')));
const Schedule = Loadable(lazy(() => import('views/Schedule')));

// pdc components
const Classroom = Loadable(lazy(() => import('views/pdc/Classroom/List')));
const AddUpdateClassroom = Loadable(lazy(() => import('views/pdc/Classroom/AddUpdate')));
const SessionList = Loadable(lazy(() => import('views/pdc/Session/List')));
const FullEdit = Loadable(lazy(() => import('views/pdc/Session/FullEdit')));
const SessionGoals = Loadable(lazy(() => import('views/pdc/Session/Goals')));
const GoalList = Loadable(lazy(() => import('views/pdc/Goals/List')));
const AddUpdateGoal = Loadable(lazy(() => import('views/pdc/Goals/AddUpdate')));

// billing components
const Claims = Loadable(lazy(() => import('views/billing/Claims')));
const Batch = Loadable(lazy(() => import('views/billing/Batch')));
const PartialPaid = Loadable(lazy(() => import('views/billing/PartialPaid')));
const Invoices = Loadable(lazy(() => import('views/billing/Invoices')));

// reports components
const PayrollReport = Loadable(lazy(() => import('views/Reports/PayrollReport')));
const MasterRipplingReport = Loadable(lazy(() => import('views/Reports/MasterRipplingReport')));
const NewPayrollReport = Loadable(lazy(() => import('views/Reports/NewPayrollReport')));
const ExportedNewPayrollReports = Loadable(lazy(() => import('views/Reports/NewPayrollReport/ExportedNewPayrollReports')));
const MasterServiceReport = Loadable(lazy(() => import('views/Reports/MasterServiceReport')));
const ServiceProvidedReport = Loadable(lazy(() => import('views/Reports/ServiceReport')));
const TreatmentEvalReport = Loadable(lazy(() => import('views/Reports/TreatmentEvalReport')));
const ViewRevenueReport = Loadable(lazy(() => import('views/Reports/ViewRevenueReport')));
const FrequencyCheckReport = Loadable(lazy(() => import('views/Reports/FrequencyCheckReport')));
const AdminFrequencyReport = Loadable(lazy(() => import('views/Reports/AdminFrequencyReport')));

const ExpirationReport = Loadable(lazy(() => import('views/Reports/ExpirationReport')));
const UnsignedReport = Loadable(lazy(() => import('views/Reports/UnsignedReport')));
const UnaccountedRevenueReport = Loadable(lazy(() => import('views/Reports/UnaccountedRevenueReport')));
const CenterReport = Loadable(lazy(() => import('views/Reports/CenterReport')));

// therapy components
const Evaluation = Loadable(lazy(() => import('views/therapy/Evaluation/List')));
const Treatment = Loadable(lazy(() => import('views/therapy/Treatment/List')));
const AddEditTreatment = Loadable(lazy(() => import('views/therapy/Treatment/AddEdit')));
const AddEditEvaluation = Loadable(lazy(() => import('views/therapy/Evaluation/AddEdit')));

// application - user social & account profile routing
const AppUserAccountProfile1 = Loadable(lazy(() => import('views/application/users/account-profile/Profile1')));

// client components
const Client = Loadable(lazy(() => import('views/Client/List')));
const AddClient = Loadable(lazy(() => import('views/Client/AddClient')));
const EditClient = Loadable(lazy(() => import('views/Client/EditClient')));

// location components
const Location = Loadable(lazy(() => import('views/Location/List')));
const AddLocation = Loadable(lazy(() => import('views/Location/AddLocation')));
const EditLocation = Loadable(lazy(() => import('views/Location/EditLocation')));

// user components
const User = Loadable(lazy(() => import('views/User/List')));
const AddUser = Loadable(lazy(() => import('views/User/AddUser')));
const EditUser = Loadable(lazy(() => import('views/User/EditUser')));

// employee components
const Employee = Loadable(lazy(() => import('views/Employee')));
const EditEmployee = Loadable(lazy(() => import('views/Employee/EditEmployee')));

// patient components
const Patient = Loadable(lazy(() => import('views/Patient/List')));
const UpadatePatient = Loadable(lazy(() => import('views/Patient/UpdatePatient')));
const DocumentDownload = Loadable(lazy(() => import('views/Patient/DocumentDownload')));
const Downloads = Loadable(lazy(() => import('views/Patient/DocumentDownload/Downloads')));

const TreatEvalPrint = Loadable(lazy(() => import('views/Reports/TreatmentEvalReport/Downloads')));

// role components
const RolePermission = Loadable(lazy(() => import('views/Permission')));

// service types components
const ServiceTypes = Loadable(lazy(() => import('views/ServiceTypes')));

// reports components
const MasterTracker = Loadable(lazy(() => import('views/Reports/MasterTracker')));

// inventory components
const Inventory = Loadable(lazy(() => import('views/DeviceManagement/Inventory')));

// contract components
const Contract = Loadable(lazy(() => import('views/Contract')));
const ContractEdit = Loadable(lazy(() => import('views/Contract/Edit')));

// mail components
const MailPage = Loadable(lazy(() => import('views/Mail')));

const Prescription = Loadable(lazy(() => import('views/Prescription')));
const MyActivityReport = Loadable(lazy(() => import('views/Reports/MyActivityReport')));
const PACheckReport = Loadable(lazy(() => import('views/Reports/PACheckReport')));
const PDCGoalReport = Loadable(lazy(() => import('views/Reports/PDCGoalReport')));

// const EidtLog = Loadable(lazy(() => import('views/Logs/Eidt')));
// const EIDTLog = Loadable(lazy(() => import('views/Logs/Eidt')));
const ActivityLog = Loadable(lazy(() => import('views/ActivityLog/ListActivityLog')));
const ActivitySetup = Loadable(lazy(() => import('views/ActivitySetup/List')));
const AddActivitySetup = Loadable(lazy(() => import('views/ActivitySetup/AddActivitySetup')));
const EditActivitySetup = Loadable(lazy(() => import('views/ActivitySetup/EditActivitySetup')));
const SupervisorLog = Loadable(lazy(() => import('views/Logs/Supervisor')));
const ConferenceLog = Loadable(lazy(() => import('views/Logs/Conference')));
const PlayGround = Loadable(lazy(() => import('views/Playground')));
const ClientInvoice = Loadable(lazy(() => import('views/ClientInvoice')));
const EarlyDismissal = Loadable(lazy(() => import('views/Reports/EarlyDismissal')));

const ITPList = Loadable(lazy(() => import('views/pdc/ITP')));
const ITPAddEdit = Loadable(lazy(() => import('views/pdc/ITP/AddEdit')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // user profile
        {
            path: '/account-profile',
            element: <AppUserAccountProfile1 />
        },
        // side bar
        {
            path: '/dashboard',
            element: <HomeDashboardTag />
            // permissions: ['dashboard_view']
        },
        {
            path: '/schedule',
            element: <Schedule />,
            permissions: ['therapy_appointments_view', 'pdc_student_schedule_view']
        },
        {
            path: '/therapy/evaluation/create/schedule/:sid',
            element: <AddEditEvaluation />
            // permissions: ['st_eval_view', 'pt_eval_view', 'ot_eval_view', 'dt_eval_view']
        },
        {
            path: '/therapy/treatment/create/schedule/:sid',
            element: <AddEditTreatment />
            // permissions: ['st_treatment_view', 'pt_treatment_view', 'ot_treatment_view', 'dt_treatment_view']
        },
        {
            path: '/patient',
            element: <Patient />,
            permissions: ['patient_view']
        },
        // {
        //     path: '/patient-overview',
        //     element: <LandingaPatient />,
        //     permissions: ['patient_view']
        // },
        {
            path: '/add-patient',
            element: <UpadatePatient />,
            permissions: ['patient_add']
        },
        {
            path: '/edit-patient/:id',
            element: <UpadatePatient />,
            permissions: ['patient_edit']
        },
        {
            path: '/patient/:id/document',
            element: <DocumentDownload />,
            permissions: ['patient_profile_download_view']
        },
        {
            path: '/patient/:id/document/downloads',
            element: <Downloads />,
            permissions: ['patient_profile_download_view']
        },
        {
            path: '/patient/document/downloads',
            element: <Downloads />,
            permissions: ['patient_profile_download_view']
        },
        {
            path: '/treat-eval/document/downloads',
            element: <TreatEvalPrint />,
            permissions: ['treatment_evaluation_print']
        },
        // reports
        {
            path: '/reports/master-tracker',
            element: <MasterTracker />,
            Permissions: ['report_master_tracker_view']
        },
        // Payroll report old
        // {
        //     path: '/reports/payroll-report',
        //     element: <PayrollReport />,
        //     Permissions: ['report_master_tracker_view']
        // },
        {
            path: '/reports/payroll-report',
            element: <MasterRipplingReport />,
            permissions: ['report_master_rippling_view']
        },
        {
            path: '/reports/new-payroll-report',
            element: <NewPayrollReport />,
            permissions: ['report_new_payroll_view']
        },
        {
            path: '/new-payroll-report/exported',
            element: <ExportedNewPayrollReports />,
            permissions: ['report_new_payroll_view']
        },
        {
            path: '/reports/master-service-report',
            element: <MasterServiceReport />,
            permissions: ['report_master_service_customer_view']
        },
        {
            path: '/reports/service-provided',
            element: <ServiceProvidedReport />,
            permissions: ['report_service_customer_view']
        },
        {
            path: '/reports/treat-eval-print',
            element: <TreatmentEvalReport />,
            permissions: ['treatment_evaluation_print']
        },
        {
            path: '/reports/view-revenue-report',
            element: <ViewRevenueReport />,
            permissions: ['report_revenue_view']
        },
        {
            path: '/reports/frequency-check',
            element: <FrequencyCheckReport />,
            permissions: ['frequency_check_report_view']
        },
        {
            path: '/reports/admin-frequency-check',
            element: <AdminFrequencyReport />,
            permissions: ['report_admin_frequency_check']
        },
        {
            path: '/reports/expiration-report',
            element: <ExpirationReport />,
            permissions: ['report_expiration_view']
        },
        {
            path: '/reports/unsigned-report',
            element: <UnsignedReport />,
            permissions: ['unsigned_notes_report_view']
        },
        {
            path: '/reports/unaccounted-revenue-report',
            element: <UnaccountedRevenueReport />,
            permissions: ['claim_view']
        },
        {
            path: '/reports/my-activity-report',
            element: <MyActivityReport />,
            permissions: ['report_myactivity_view']
        },
        {
            path: '/reports/center-report',
            element: <CenterReport />,
            permissions: ['center_report_view']
        },
        {
            path: '/reports/early-dismissal-report',
            element: <EarlyDismissal />,
            permissions: ['early_dismissal_report_view']
        },
        {
            path: '/reports/pa-check-report',
            element: <PACheckReport />,
            permissions: ['report_prior_auth_view']
        },
        {
            path: '/reports/pdc-goal-report',
            element: <PDCGoalReport />,
            permissions: ['report_pdc_goals_view']
        },

        // therapy
        {
            path: '/therapy/:therapy/evaluation',
            element: <Evaluation />,
            permissions: ['st_eval_view', 'pt_eval_view', 'ot_eval_view', 'dt_eval_view']
        },
        {
            path: '/therapy/:therapy/evaluation/create',
            element: <AddEditEvaluation />,
            permissions: ['st_eval_view', 'pt_eval_view', 'ot_eval_view', 'dt_eval_view']
        },
        {
            path: '/therapy/:therapy/evaluation/edit/:tid',
            element: <AddEditEvaluation />,
            permissions: ['st_eval_view', 'pt_eval_view', 'ot_eval_view', 'dt_eval_view']
        },
        {
            path: '/therapy/:therapy/treatment/',
            element: <Treatment />,
            permissions: ['st_note_view', 'pt_note_view', 'ot_note_view', 'dt_note_view']
        },
        {
            path: '/therapy/:therapy/treatment/create',
            element: <AddEditTreatment />,
            permissions: ['st_note_view', 'pt_note_view', 'ot_note_view', 'dt_note_view']
        },
        {
            path: '/therapy/:therapy/treatment/edit/:tid',
            element: <AddEditTreatment />,
            permissions: ['st_note_view', 'pt_note_view', 'ot_note_view', 'dt_note_view']
        },

        // pdc
        {
            path: '/:classtype/session',
            element: <SessionList />,
            permissions: ['pdc_cls_session_view']
        },
        {
            path: '/:classtype/session/:id',
            element: <FullEdit />,
            permissions: ['pdc_cls_session_view']
        },
        {
            path: '/pdc/goal',
            element: <GoalList />,
            permissions: ['pdc_student_goal_view']
        },
        {
            path: '/pdc/goal/add/:stid',
            element: <AddUpdateGoal />,
            permissions: ['pdc_student_goal_add']
        },
        // {
        //     path: '/pdc/goal/edit/:id',
        //     element: <AddUpdateGoal />
        // },
        {
            path: '/pdc/session/:id/goals',
            element: <SessionGoals />,
            permissions: ['pdc_student_goal_progress']
        },
        {
            path: '/:itpType/itp',
            element: <ITPList />,
            permissions: ['itp_view', 'therapy_itp_view']
        },
        {
            path: '/:itpType/itp/add',
            element: <ITPAddEdit />,
            permissions: ['itp_add', 'therapy_itp_add']
        },
        {
            path: '/:itpType/itp/edit/:id',
            element: <ITPAddEdit />,
            permissions: ['itp_view', 'therapy_itp_view']
        },
        // device management
        {
            path: '/inventory-management/assets',
            element: <Inventory />,
            permissions: ['assets_view']
        },

        // prescription
        {
            path: '/prescription',
            element: <Prescription />,
            permissions: ['prescription_view']
        },

        // settings
        {
            path: '/settings/client',
            element: <Client />,
            permissions: ['client_view']
        },
        {
            path: '/settings/add-client',
            element: <AddClient />,
            permissions: ['client_add']
        },
        {
            path: '/settings/edit-client/:id',
            element: <EditClient />,
            permissions: ['client_edit']
        },
        {
            path: '/settings/location',
            element: <Location />,
            permissions: ['location_view']
        },
        {
            path: '/settings/add-location',
            element: <AddLocation />,
            permissions: ['location_create']
        },
        {
            path: '/settings/edit-location/:id',
            element: <EditLocation />,
            permissions: ['location_edit']
        },

        {
            path: '/settings/employee',
            element: <Employee />,
            permissions: ['employee_view']
        },
        {
            path: '/settings/activity-setup',
            element: <ActivitySetup />,
            permissions: ['activity_log_setup_view']
        },
        {
            path: '/settings/add-activity-setup',
            element: <AddActivitySetup />,
            permissions: ['activity_log_setup_create']
        },
        {
            path: '/settings/edit-activity-setup/:id',
            element: <EditActivitySetup />,
            permissions: ['activity_log_setup_edit']
        },
        {
            path: '/settings/add-employee',
            element: <EditEmployee />,
            permissions: ['employee_create']
        },
        {
            path: '/settings/edit-employee/:id',
            element: <EditEmployee />,
            permissions: ['employee_edit']
        },
        {
            path: '/settings/client-invoice',
            element: <ClientInvoice />,
            permissions: ['client_invoice_view']
        },
        // Classroom
        {
            path: '/settings/:classtype/classroom',
            element: <Classroom />,
            permissions: ['classroom_view']
        },
        {
            path: '/settings/:classtype/add-classroom',
            element: <AddUpdateClassroom />,
            permissions: ['classroom_add']
        },
        {
            path: '/settings/:classtype/edit-classroom/:id',
            element: <AddUpdateClassroom />,
            permissions: ['classroom_view']
        },
        {
            path: '/settings/service-types',
            element: <ServiceTypes />,
            permissions: ['service_types_view']
        },

        {
            path: '/settings/role-permission',
            element: <RolePermission />,
            permissions: ['role_permission_view']
        },
        {
            path: '/settings/contract',
            element: <Contract />,
            permissions: ['contract_view']
        },
        {
            path: '/settings/edit-contract/:id',
            element: <ContractEdit />,
            permissions: ['contract_edit']
        },
        {
            path: '/settings/add-contract',
            element: <ContractEdit />,
            permissions: ['contract_create']
        },
        {
            path: '/user',
            element: <User />
        },
        {
            path: '/add-user',
            element: <AddUser />
        },
        {
            path: '/edit-user',
            element: <EditUser />
        },
        {
            path: '/edit-user',
            element: <EditUser />
        },
        {
            path: '/mail',
            element: <MailPage />,
            permissions: ['email_view']
        },
        // {
        //   path: "/logs/eidt",
        //   element: <EidtLog />,
        //   permissions: [],
        // },
        {
            path: '/activity-log',
            element: <ActivityLog />,
            permissions: []
        },
        {
            path: '/logs/supervisor',
            element: <SupervisorLog />,
            permissions: []
        },
        {
            path: '/logs/conference',
            element: <ConferenceLog />,
            permissions: []
        },
        // {
        //   path: "/logs/eidt",
        //   element: <EIDTLog />,
        //   permissions: [],
        // },

        // billing
        {
            path: '/billing/claims',
            element: <Claims />,
            permissions: ['claim_view']
        },
        {
            path: '/billing/batch',
            element: <Batch />,
            permissions: ['batch_view']
        },
        {
            path: '/billing/partial-paid',
            element: <PartialPaid />,
            permissions: ['batch_view']
        },
        {
            path: '/billing/invoices',
            element: <Invoices />
        },

        // playground
        {
            path: '/playground',
            element: <PlayGround />,
            Permissions: []
        }
    ]
};

export default MainRoutes;
