// third-party
import { FormattedMessage } from 'react-intl';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {
    IconBooks,
    IconCalendar,
    IconCalendarEvent,
    IconClipboardCopy,
    IconDashboard,
    IconDeviceAnalytics,
    IconDevices,
    IconList,
    IconMail,
    IconMapPin,
    IconPhysotherapist,
    IconPrescription,
    IconReceipt2,
    IconReportAnalytics,
    IconSchool,
    IconSettings,
    IconUserCheck,
    IconUserCircle,
    IconUserPlus
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconCalendar,
    IconUserCheck,
    IconUserCircle,
    IconMapPin,
    IconPhysotherapist,
    IconSchool,
    IconReceipt2,
    IconReportAnalytics,
    IconSettings,
    IconCalendarEvent,
    PersonAddAltIcon,
    IconUserPlus,
    IconDevices,
    IconMail,
    IconClipboardCopy,
    IconBooks,
    IconList,
    IconPrescription
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard', // <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
            permissions: [],
            searchKey: 'dashboard'
        },
        {
            id: 'schedule',
            title: 'Schedule', // <FormattedMessage id="Schedule" />,
            type: 'item',
            url: '/schedule',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false,
            permissions: ['therapy_appointments_view', 'pdc_student_schedule_view'],
            searchKey: 'schedule'
        },
        {
            id: 'patient',
            title: 'Patient',
            type: 'item',
            icon: icons.IconUserPlus,
            url: '/patient',
            breadcrumbs: false,
            permissions: ['patient_view'],
            searchKey: 'patient'
        },
        {
            id: 'therapy',
            title: 'Therapy',
            type: 'collapse',
            icon: icons.IconClipboardCopy,
            breadcrumbs: false,
            permissions: [
                'st_eval_view',
                'st_note_view',
                'pt_eval_view',
                'pt_note_view',
                'ot_eval_view',
                'ot_note_view',
                'dt_eval_view',
                'dt_note_view'
            ],
            isService: true,
            services: ['ST', 'PT', 'OT', 'DT'],
            searchKey: 'therapy',
            children: [
                {
                    id: 'therapy-st',
                    title: 'Speech Therapy',
                    type: 'collapse',
                    url: '/therapy/st',
                    breadcrumbs: false,
                    permissions: ['st_eval_view', 'st_note_view'],
                    isService: true,
                    services: 'ST',
                    searchKey: 'speech therapy',
                    children: [
                        {
                            id: 'therapy-st-evaluation',
                            title: 'Evaluation',
                            type: 'item',
                            url: '/therapy/st/evaluation',
                            breadcrumbs: false,
                            permissions: ['st_eval_view'],
                            searchKey: 'st evaluation'
                        },
                        {
                            id: 'therapy-st-treatment',
                            title: 'Treatment',
                            type: 'item',
                            url: '/therapy/st/treatment',
                            breadcrumbs: false,
                            permissions: ['st_note_view'],
                            searchKey: 'st treatment'
                        }
                    ]
                },
                {
                    id: 'therapy-pt',
                    title: 'Physical Therapy',
                    type: 'collapse',
                    url: '/therapy/pt',
                    breadcrumbs: false,
                    permissions: ['pt_eval_view', 'pt_note_view'],
                    isService: true,
                    services: 'PT',
                    searchKey: 'physical therapy',
                    children: [
                        {
                            id: 'therapy-pt-evaluation',
                            title: 'Evaluation',
                            type: 'item',
                            url: '/therapy/pt/evaluation',
                            breadcrumbs: false,
                            permissions: ['pt_eval_view'],
                            searchKey: 'pt evaluation'
                        },
                        {
                            id: 'therapy-pt-treatment',
                            title: 'Treatment',
                            type: 'item',
                            url: '/therapy/pt/treatment',
                            breadcrumbs: false,
                            permissions: ['pt_note_view'],
                            searchKey: 'pt treatment'
                        }
                    ]
                },
                {
                    id: 'therapy-ot',
                    title: 'Occupational Therapy',
                    type: 'collapse',
                    url: '/therapy/ot',
                    breadcrumbs: false,
                    permissions: ['ot_eval_view', 'ot_note_view'],
                    isService: true,
                    services: 'OT',
                    searchKey: 'occupational therapy',
                    children: [
                        {
                            id: 'therapy-ot-evaluation',
                            title: 'Evaluation',
                            type: 'item',
                            url: '/therapy/ot/evaluation',
                            breadcrumbs: false,
                            permissions: ['ot_eval_view'],
                            searchKey: 'ot evaluation'
                        },
                        {
                            id: 'therapy-ot-treatment',
                            title: 'Treatment',
                            type: 'item',
                            url: '/therapy/ot/treatment',
                            breadcrumbs: false,
                            permissions: ['ot_note_view'],
                            searchKey: 'ot treatment'
                        }
                    ]
                },
                {
                    id: 'therapy-dt',
                    title: 'Developmental Therapy',
                    type: 'collapse',
                    url: '/therapy/dt',
                    breadcrumbs: false,
                    permissions: ['dt_eval_view', 'dt_note_view'],
                    isService: true,
                    services: 'DT',
                    searchKey: 'developmental therapy',
                    children: [
                        {
                            id: 'therapy-dt-evaluation',
                            title: 'Evaluation',
                            type: 'item',
                            url: '/therapy/dt/evaluation',
                            breadcrumbs: false,
                            permissions: ['dt_eval_view'],
                            searchKey: 'dt evaluation'
                        },
                        {
                            id: 'therapy-dt-treatment',
                            title: 'Treatment',
                            type: 'item',
                            url: '/therapy/dt/treatment',
                            breadcrumbs: false,
                            permissions: ['dt_note_view'],
                            searchKey: 'dt treatment'
                        }
                    ]
                },
                {
                    id: 'therapy-itp',
                    title: 'Quarter Review',
                    type: 'item',
                    url: '/therapy/itp',
                    breadcrumbs: false,
                    permissions: ['therapy_itp_view'],
                    searchKey: 'quarter review'
                }
            ]
        },
        {
            id: 'pdc',
            title: 'PDC',
            type: 'collapse',
            icon: icons.IconSchool,
            breadcrumbs: false,
            permissions: ['pdc_cls_session_view'],
            searchKey: 'pdc',
            children: [
                {
                    id: 'pdc-sesssion',
                    title: 'Session',
                    type: 'item',
                    url: '/pdc/session',
                    breadcrumbs: false,
                    permissions: ['pdc_cls_session_view'],
                    searchKey: 'pdc session'
                },
                {
                    id: 'pdc-goals',
                    title: 'Goals',
                    type: 'item',
                    url: '/pdc/goal',
                    breadcrumbs: false,
                    permissions: ['pdc_student_goal_view'],
                    searchKey: 'pdc goals'
                },
                {
                    id: 'itp',
                    title: 'ITP',
                    type: 'item',
                    url: '/pdc/itp',
                    breadcrumbs: false,
                    permissions: ['itp_view'],
                    searchKey: 'pdc itp'
                }
            ]
        },
        {
            id: 'addt',
            title: 'ADDT',
            type: 'collapse',
            icon: icons.IconBooks,
            breadcrumbs: false,
            permissions: ['pdc_cls_session_view'],
            searchKey: 'addt',
            children: [
                {
                    id: 'addt-sesssion',
                    title: 'Session',
                    type: 'item',
                    url: '/addt/session',
                    breadcrumbs: false,
                    permissions: ['pdc_cls_session_view'],
                    searchKey: 'addt session'
                }
            ]
        },
        {
            id: 'billing',
            title: 'Billing',
            type: 'collapse',
            icon: icons.IconReceipt2,
            breadcrumbs: false,
            permissions: ['claim_view', 'batch_view'],
            searchKey: 'billing',
            children: [
                {
                    id: 'billing-claims',
                    title: 'Claims',
                    type: 'item',
                    url: '/billing/claims',
                    breadcrumbs: false,
                    permissions: ['claim_view'],
                    searchKey: 'claims'
                },
                {
                    id: 'billing-batch',
                    title: 'Batch',
                    type: 'item',
                    url: '/billing/batch',
                    breadcrumbs: false,
                    permissions: ['batch_view'],
                    searchKey: 'batch'
                },
                {
                    id: 'partial-paid',
                    title: 'Partial Claims',
                    type: 'item',
                    url: '/billing/partial-paid',
                    breadcrumbs: false,
                    permissions: ['batch_view'],
                    searchKey: 'partial claims'
                }
                // {
                //     id: 'billing-invoices',
                //     title: 'Invoices',
                //     type: 'item',
                //     url: '/billing/invoices',
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false,
            permissions: [
                'report_master_tracker_view',
                'report_master_rippling_view',
                'report_new_payroll_view',
                'report_service_customer_view',
                'report_master_service_customer_view',
                'frequency_check_report_view',
                'report_expiration_view',
                'unsigned_notes_report_view',
                'report_myactivity_view',
                'center_report_view',
                'early_dismissal_report_view',
                'treatment-eval-notes',
                'report_revenue_view'
            ],
            searchKey: 'reports',
            children: [
                {
                    id: 'reports-master-tracker',
                    title: 'Master Tracker',
                    type: 'item',
                    url: '/reports/master-tracker',
                    breadcrumbs: false,
                    permissions: ['report_master_tracker_view'],
                    searchKey: 'master tracker'
                },
                // {
                //     id: 'reports-payroll-report',
                //     title: 'Payroll Report',
                //     type: 'item',
                //     url: '/reports/payroll-report',
                //     breadcrumbs: false,
                //     permissions: ['report_master_tracker_view']
                // },
                {
                    id: 'payroll-report',
                    title: 'Payroll Report',
                    type: 'item',
                    url: '/reports/payroll-report',
                    breadcrumbs: false,
                    permissions: ['report_master_rippling_view'],
                    searchKey: 'payroll report'
                },
                {
                    id: 'reports-new-payroll-report',
                    title: 'Rippling Payroll Report',
                    type: 'item',
                    url: '/reports/new-payroll-report',
                    breadcrumbs: false,
                    permissions: ['report_new_payroll_view'],
                    searchKey: 'rippling payroll report'
                },
                {
                    id: 'master-service-report',
                    title: 'Master Service Report',
                    type: 'item',
                    url: '/reports/master-service-report',
                    breadcrumbs: false,
                    permissions: ['report_master_service_customer_view'],
                    searchKey: 'master service report'
                },
                {
                    id: 'reports-service-provided',
                    title: 'Service Report',
                    type: 'item',
                    url: '/reports/service-provided',
                    breadcrumbs: false,
                    permissions: ['report_service_customer_view'],
                    searchKey: 'service report'
                },
                // {
                //     id: 'treat-eval-print',
                //     title: 'Print Documents',
                //     type: 'item',
                //     url: '/reports/treat-eval-print',
                //     breadcrumbs: false,
                //     permissions: ['treatment_evaluation_print'],
                //     searchKey: 'Print Documents'
                // },
                {
                    id: 'view-revenue-report',
                    title: 'View Revenue Report',
                    type: 'item',
                    url: '/reports/view-revenue-report',
                    breadcrumbs: false,
                    permissions: ['report_revenue_view'],
                    searchKey: 'view revenue report'
                },
                {
                    id: 'reports-frequency-check',
                    title: 'Frequency Check Report',
                    type: 'item',
                    url: '/reports/frequency-check',
                    breadcrumbs: false,
                    permissions: ['frequency_check_report_view'],
                    searchKey: 'frequency check report'
                },
                {
                    id: 'admin-reports-frequency-check',
                    title: 'Admin Frequency Check Report',
                    type: 'item',
                    url: '/reports/admin-frequency-check',
                    breadcrumbs: false,
                    permissions: ['report_admin_frequency_check'],
                    searchKey: 'admin frequency check report'
                },
                {
                    id: 'reports-expiration',
                    title: 'Expiration Report',
                    type: 'item',
                    url: '/reports/expiration-report',
                    breadcrumbs: false,
                    permissions: ['report_expiration_view'],
                    searchKey: 'expiration report'
                },
                {
                    id: 'unsigned-report',
                    title: 'Unsigned Report',
                    type: 'item',
                    url: '/reports/unsigned-report',
                    breadcrumbs: false,
                    permissions: ['unsigned_notes_report_view'],
                    searchKey: 'unsigned report'
                },
                {
                    id: 'unaccounted-revenue-report',
                    title: 'Unaccounted Revenue Report',
                    type: 'item',
                    url: '/reports/unaccounted-revenue-report',
                    breadcrumbs: false,
                    permissions: ['claim_view'],
                    searchKey: 'unaccounted revenue report'
                },
                {
                    id: 'my-activity-report',
                    title: 'My Activity Report',
                    type: 'item',
                    url: '/reports/my-activity-report',
                    breadcrumbs: false,
                    permissions: ['report_myactivity_view'],
                    searchKey: 'my activity report'
                },
                {
                    id: 'center-report',
                    title: 'Center Report',
                    type: 'item',
                    url: '/reports/center-report',
                    breadcrumbs: false,
                    permissions: ['center_report_view'],
                    searchKey: 'center report'
                },
                {
                    id: 'early-dismissal-report',
                    title: 'Early Dismissal Report',
                    type: 'item',
                    url: '/reports/early-dismissal-report',
                    breadcrumbs: false,
                    permissions: ['early_dismissal_report_view'],
                    searchKey: 'early dismissal report'
                },
                {
                    id: 'pa-check-report',
                    title: 'PA#/Unit Check',
                    type: 'item',
                    url: '/reports/pa-check-report',
                    breadcrumbs: false,
                    permissions: ['report_prior_auth_view'],
                    searchKey: 'pa#/unit check report'
                },
                {
                    id: 'pdc-goal-report',
                    title: 'PDC Goal Report',
                    type: 'item',
                    url: '/reports/pdc-goal-report',
                    breadcrumbs: false,
                    permissions: ['report_pdc_goals_view'],
                    searchKey: 'pdc goal report'
                }
            ]
        },
        {
            id: 'device-management',
            title: 'Inventory Management',
            type: 'collapse',
            icon: icons.IconDevices,
            breadcrumbs: false,
            permissions: ['assets_view'],
            searchKey: 'inventory management',
            children: [
                {
                    id: 'assets',
                    title: 'Assets',
                    type: 'item',
                    url: '/inventory-management/assets',
                    breadcrumbs: false,
                    permissions: ['assets_view'],
                    searchKey: 'assets'
                }
            ]
        },
        {
            id: 'mail',
            title: 'Mail',
            type: 'item',
            url: '/mail',
            icon: icons.IconMail,
            breadcrumbs: false,
            permissions: ['email_view'],
            searchKey: 'mail'
        },
        // {
        //     id: 'prescription',
        //     title: 'Prescription',
        //     type: 'item',
        //     url: '/prescription',
        //     icon: icons.IconPrescription,
        //     breadcrumbs: false,
        //     permissions: ['prescription_view']
        // },
        // {
        //     id: 'claims',
        //     title: 'Claims',
        //     type: 'item',
        //     icon: icons.IconPhysotherapist,
        //     breadcrumbs: false
        // },
        {
            id: 'logs',
            title: 'Activity Log',
            type: 'item',
            url: '/activity-log',
            icon: icons.IconList,
            breadcrumbs: false,
            permissions: [],
            searchKey: 'activity log'
            // children: [
            //     {
            //         id: 'activity',
            //         title: 'Conference Log',
            //         type: 'item',
            //         url: '/logs/conference',
            //         breadcrumbs: false,
            //         permissions: []
            //     },
            //     {
            //         id: 'eidt',
            //         title: 'Supervisor Log',
            //         type: 'item',
            //         url: '/logs/supervisor',
            //         breadcrumbs: false,
            //         permissions: []
            //     }
            // ]
        },
        {
            id: 'settings',
            title: 'Settings',
            type: 'collapse',
            icon: icons.IconSettings,
            breadcrumbs: false,
            permissions: ['client_view', 'location_view', 'employee_view', 'classroom_view', 'service_types_view', 'role_permission_view'],
            searchKey: 'settings',
            children: [
                {
                    id: 'settings-client',
                    title: 'Client',
                    type: 'item',
                    url: '/settings/client',
                    breadcrumbs: false,
                    permissions: ['client_view'],
                    searchKey: 'client'
                },
                {
                    id: 'settings-location',
                    title: 'Location',
                    type: 'item',
                    url: '/settings/location',
                    breadcrumbs: false,
                    permissions: ['location_view'],
                    searchKey: 'location'
                },
                {
                    id: 'settings-employee',
                    title: 'Employee',
                    type: 'item',
                    url: '/settings/employee',
                    breadcrumbs: false,
                    permissions: ['employee_view'],
                    searchKey: 'employee'
                },
                {
                    id: 'settings-activity-setup',
                    title: 'Activity Setup',
                    type: 'item',
                    url: '/settings/activity-setup',
                    breadcrumbs: false,
                    permissions: ['activity_log_setup_view'],
                    searchKey: 'activity setup'
                },
                {
                    id: 'settings-client-invoice',
                    title: 'Client Invoice',
                    type: 'item',
                    url: '/settings/client-invoice',
                    breadcrumbs: false,
                    permissions: ['client_invoice_view'],
                    searchKey: 'client invoice'
                },
                {
                    id: 'settings-pdc-classroom',
                    title: 'PDC Classroom',
                    type: 'item',
                    url: '/settings/pdc/classroom',
                    breadcrumbs: false,
                    permissions: ['classroom_view'],
                    searchKey: 'pdc classroom'
                },
                {
                    id: 'settings-addt-classroom',
                    title: 'ADDT Classroom',
                    type: 'item',
                    url: '/settings/addt/classroom',
                    breadcrumbs: false,
                    permissions: ['classroom_view'],
                    searchKey: 'addt classroom'
                },
                {
                    id: 'settings-service-types',
                    title: 'Service Rates',
                    type: 'item',
                    url: '/settings/service-types',
                    breadcrumbs: false,
                    permissions: ['service_types_view'],
                    searchKey: 'service rates'
                },
                {
                    id: 'settings-role-permission',
                    title: 'Role Permission',
                    type: 'item',
                    url: '/settings/role-permission',
                    breadcrumbs: false,
                    permissions: ['role_permission_view'],
                    searchKey: 'role permission'
                }
            ]
        }
    ]
};

export default dashboard;
